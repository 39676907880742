// src/components/Dashboard/DashboardContent.js
import React from 'react';

const DashboardContent = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Últimas Cotizaciones */}
      <div className="bg-white p-4 shadow rounded">
        <h2 className="text-lg font-semibold mb-2">Últimas Cotizaciones</h2>
        {/* Aquí podrías mapear tus cotizaciones */}
        <ul>
          <li className="border-b py-2">Cotización 1</li>
          <li className="border-b py-2">Cotización 2</li>
          <li className="border-b py-2">Cotización 3</li>
        </ul>
      </div>

      {/* Próximas Citas */}
      <div className="bg-white p-4 shadow rounded">
        <h2 className="text-lg font-semibold mb-2">Próximas Citas</h2>
        {/* Aquí podrías mapear tus citas */}
        <ul>
          <li className="border-b py-2">Cita con Cliente A - 10:00 AM</li>
          <li className="border-b py-2">Cita con Cliente B - 2:00 PM</li>
          <li className="border-b py-2">Cita con Cliente C - 4:00 PM</li>
        </ul>
      </div>

      {/* Información Importante */}
      <div className="bg-white p-4 shadow rounded">
        <h2 className="text-lg font-semibold mb-2">Información Importante</h2>
        <p>Aquí puedes mostrar cualquier información relevante para el usuario.</p>
      </div>
    </div>
  );
};

export default DashboardContent;
