// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Crear el contexto
export const AuthContext = createContext();

// Proveedor del contexto
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Función para cargar el usuario desde el token
    const loadUser = async () => {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            try {
                const response = await axios.get('http://localhost:8080/api/accounts/me/', {
                // const response = await axios.get('https://cotizame.lat/api/accounts/me/', {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.error('Error al cargar el usuario:', error);
                setUser(null);
                // Opcional: limpiar tokens si son inválidos
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        loadUser();
    }, []);

    // Función para iniciar sesión
    const login = (userData) => {
        setUser(userData);
    };

    // Función para cerrar sesión
    const logout = () => {
        setUser(null);
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
