// src/components/ui/card.jsx
import React from 'react';
import { cn } from '../../utils/cn';

const Card = ({ className, children, ...props }) => {
  return (
    <div
      className={cn('bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300', className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default Card;
