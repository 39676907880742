// src/components/landingPage/Timeline.jsx
import React from 'react';
import Card from '../ui/card'; // Importar card personalizado

const Timeline = () => {
    const steps = [
        {
            title: 'Cotizaciones Automatizadas',
            description: (
                <>
                    Genera cotizaciones precisas en segundos, permitiéndote responder rápidamente a tus clientes y cerrar más ventas. Nuestro sistema automatiza el proceso, reduciendo errores y aumentando la eficiencia, lo que te permite enfocarte en lo que realmente importa: <strong>hacer crecer tu negocio</strong>.
                </>
            ),
            image: '/images/timeline/cotizaciones-detail.png',
        },
        {
            title: 'Generación de Citas Inteligentes',
            description: (
                <>
                    Nuestro sistema de calendario inteligente facilita la programación de citas, <strong>optimizando tu agenda</strong> y <strong>mejorando la satisfacción del cliente</strong>. Al automatizar la gestión de citas, reduces el tiempo dedicado a la organización manual y minimizas los conflictos de horarios, asegurando una <strong>experiencia fluida para tus clientes</strong>.
                </>
            ),
            image: '/images/timeline/citas-detail.jpeg',
        },
        {
            title: 'Informes Detallados',
            description: (
                <>
                    Obtén informes automáticos que te proporcionan <strong>insights valiosos sobre tu negocio</strong>, ayudándote a <strong>tomar decisiones informadas</strong>. Analiza tendencias, identifica oportunidades de mejora y monitorea el rendimiento de tus estrategias de ventas con datos precisos y fáciles de interpretar.
                </>
            ),
            image: '/images/timeline/informes-detail.png',
        },
        {
            title: 'Búsqueda de FindCustomers Potenciales',
            description: (
                <>
                    Accede a una base de datos robusta para encontrar clientes potenciales en tu área, <strong>ampliando tu alcance y aumentando tus oportunidades de negocio</strong>.
                </>
            ),
            image: '/images/timeline/busqueda-detail.png',
        },
    ];

    return (
        <section className="timeline-section w-11/12 max-w-6xl mb-16 mx-auto">
            <h2 className="text-4xl font-semibold mb-10 text-primary text-center">Detalles de Nuestros Servicios</h2>
            <div className="relative">

                <div className="space-y-20">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`flex flex-col md:flex-row items-center ${
                                index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'
                            }`}
                        >
                            {/* Punto en la Línea del Tiempo */}
                            <div className="flex items-center justify-center w-12 h-12 bg-primary text-white rounded-full shadow-md z-10">
                                <span className="font-bold">{index + 1}</span>
                            </div>

                            {/* Contenido del Paso */}
                            <div className="mt-6 md:mt-0 md:w-5/12">
                                <Card className="p-6">
                                    <h3 className="text-2xl font-semibold mb-3 text-primary">{step.title}</h3>
                                    <p className="text-gray-700">{step.description}</p>
                                </Card>
                            </div>

                            {/* Imagen del Paso */}
                            <div className="mt-6 md:mt-0 md:w-5/12 flex justify-center">
                                <img
                                    src={step.image}
                                    alt={`Detalle de ${step.title}`}
                                    className="w-full h-auto object-cover rounded-lg shadow-md"
                                />
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default Timeline;
