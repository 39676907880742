// src/components/ProtectedRoute.js
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const { user, loading } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={(props) =>
                loading ? (
                    // Puedes mostrar un spinner o una pantalla de carga aquí
                    <div className="flex items-center justify-center h-screen">
                        <div className="loader">Cargando...</div>
                    </div>
                ) : user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

export default ProtectedRoute;
