// src/components/Dashboard/FindCustomers.js
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Importar componentes de shadcn UI
import { Input } from '../ui/input';
import Button from '../ui/button';
import { Table, TableBody, TableCell, TableHeadCell, TableHeader, TableRow } from '../ui/table';
import { Label } from '../ui/label';

// Importar imágenes de Leaflet para los iconos
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Configuración de Leaflet
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const FindCustomers = () => {
  const [tags, setTags] = useState('');
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [mapKey, setMapKey] = useState(0);

  // Datos sintéticos de negocios
  const businesses = [
    {
      id: 1,
      nombre: 'Cafetería El Buen Café',
      telefono: '123-456-7890',
      correo: 'contacto@elbuencafe.com',
      redes: {
        facebook: 'facebook.com/elbuencafe',
        instagram: 'instagram.com/elbuencafe',
      },
      etiquetas: ['cafetería', 'bebidas', 'panadería'],
      ubicacion: { lat: 40.7128, lng: -74.006 },
    },
    {
      id: 2,
      nombre: 'Panadería La Dulce Vida',
      telefono: '098-765-4321',
      correo: 'info@ladulcevida.com',
      redes: {
        facebook: 'facebook.com/ladulcevida',
        instagram: 'instagram.com/ladulcevida',
      },
      etiquetas: ['panadería', 'alimentos', 'pastelería'],
      ubicacion: { lat: 40.73061, lng: -73.935242 },
    },
    {
      id: 3,
      nombre: 'Papelería OficinaPlus',
      telefono: '555-123-4567',
      correo: 'ventas@oficinaplus.com',
      redes: {
        facebook: 'facebook.com/oficinaplus',
        instagram: 'instagram.com/oficinaplus',
      },
      etiquetas: ['papelería', 'oficinas', 'materiales'],
      ubicacion: { lat: 40.758896, lng: -73.98513 },
    },
    {
      id: 4,
      nombre: 'Cafetería La Esquina',
      telefono: '222-333-4444',
      correo: 'contacto@laesquina.com',
      redes: {
        facebook: 'facebook.com/laesquina',
        instagram: 'instagram.com/laesquina',
      },
      etiquetas: ['cafetería', 'alimentos', 'desayuno'],
      ubicacion: { lat: 40.7291, lng: -73.9965 },
    },
    {
      id: 5,
      nombre: 'Oficina Central',
      telefono: '111-222-3333',
      correo: 'info@oficinacentral.com',
      redes: {
        facebook: 'facebook.com/oficinacentral',
        instagram: 'instagram.com/oficinacentral',
      },
      etiquetas: ['oficinas', 'corporativo', 'servicios'],
      ubicacion: { lat: 40.748817, lng: -73.985428 },
    },
    {
      id: 6,
      nombre: 'Cafetería Aroma',
      telefono: '333-444-5555',
      correo: 'contacto@aromacafe.com',
      redes: {
        facebook: 'facebook.com/aromacafe',
        instagram: 'instagram.com/aromacafe',
      },
      etiquetas: ['cafetería', 'bebidas', 'comida'],
      ubicacion: { lat: 40.741895, lng: -73.989308 },
    },
    {
      id: 7,
      nombre: 'Oficina Express',
      telefono: '444-555-6666',
      correo: 'info@oficinaexpress.com',
      redes: {
        facebook: 'facebook.com/oficinaexpress',
        instagram: 'instagram.com/oficinaexpress',
      },
      etiquetas: ['oficinas', 'servicios', 'consultoría'],
      ubicacion: { lat: 40.752726, lng: -73.977229 },
    },
    // Agrega más negocios sintéticos según sea necesario
  ];

  useEffect(() => {
    // Inicializar la tabla con todos los negocios al montar el componente
    setFilteredBusinesses(businesses);
    console.log('Negocios iniciales:', businesses);
  }, []);

  const handleSearch = () => {
    const tagsArray = tags.split(',').map(tag => tag.trim().toLowerCase());

    console.log('Tags ingresados:', tagsArray); // Debugging

    // Manejar caso donde el input está vacío
    if (tagsArray.length === 0 || (tagsArray.length === 1 && tagsArray[0] === '')) {
      setFilteredBusinesses(businesses);
      setSelectedBusiness(null);
      setMapKey(prevKey => prevKey + 1); // Actualizar el mapa
      console.log('No se ingresaron tags, mostrando todos los negocios');
      return;
    }

    const resultados = businesses.filter(business =>
      tagsArray.every(tag => business.etiquetas.includes(tag))
    );

    console.log('Busqueda realizada:', resultados); // Debugging

    setFilteredBusinesses(resultados);
    setSelectedBusiness(null);
    setMapKey(prevKey => prevKey + 1); // Actualizar el mapa
  };

  const handleRowClick = (business) => {
    setSelectedBusiness(business);
    setMapKey(prevKey => prevKey + 1); // Actualizar el mapa
    console.log('Negocio seleccionado:', business);
  };

  return (
    <div className="p-4 space-y-6">
      <h2 className="text-2xl font-semibold">Encontrar Nuevos Clientes</h2>

      {/* Entrada de etiquetas */}
      <div className="space-y-2">
        <Label htmlFor="tags">Introduce etiquetas (separadas por comas):</Label>
        <div className="flex space-x-2">
          <Input
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="ej. panadería, cafetería"
            className="flex-grow"
          />
          <Button onClick={handleSearch} variant="default" size="md">
            Buscar
          </Button>
        </div>
      </div>

      {/* Contenedor de la Tabla y el Mapa */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Tabla de negocios */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Resultados de la búsqueda</h3>
          {filteredBusinesses.length > 0 ? (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeadCell>Nombre del Negocio</TableHeadCell>
                  <TableHeadCell>Teléfono</TableHeadCell>
                  <TableHeadCell>Correo</TableHeadCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredBusinesses.map(business => (
                  <TableRow
                    key={business.id}
                    onClick={() => handleRowClick(business)}
                    className={`cursor-pointer hover:bg-gray-100 ${selectedBusiness?.id === business.id ? 'bg-blue-100' : ''}`}
                  >
                    <TableCell>{business.nombre}</TableCell>
                    <TableCell>{business.telefono}</TableCell>
                    <TableCell>{business.correo}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <p className="text-gray-500">No se encontraron negocios con esas etiquetas.</p>
          )}
        </div>

        {/* Mapa y detalles del negocio */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Mapa y detalles del negocio</h3>
          {selectedBusiness ? (
            <div className="space-y-4">
              {/* Mapa */}
              <div className="h-64">
                <MapContainer
                  key={mapKey}
                  center={[selectedBusiness.ubicacion.lat, selectedBusiness.ubicacion.lng]}
                  zoom={13}
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <Marker position={[selectedBusiness.ubicacion.lat, selectedBusiness.ubicacion.lng]}>
                    <Popup>
                      <strong>{selectedBusiness.nombre}</strong>
                      <br />
                      {selectedBusiness.correo}
                    </Popup>
                  </Marker>
                </MapContainer>
              </div>

              {/* Detalles del negocio */}
              <div className="space-y-2">
                <h4 className="font-medium">{selectedBusiness.nombre}</h4>
                <p>Teléfono: {selectedBusiness.telefono}</p>
                <p>Correo: {selectedBusiness.correo}</p>
                <p>Etiquetas: {selectedBusiness.etiquetas.join(', ')}</p>
                <div className="flex space-x-2">
                  <a
                    href={`https://${selectedBusiness.redes.facebook}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    Facebook
                  </a>
                  <a
                    href={`https://${selectedBusiness.redes.instagram}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pink-500 hover:underline"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-gray-500">Selecciona un negocio de la tabla para ver su ubicación en el mapa y más detalles.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FindCustomers;
