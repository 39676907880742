// src/components/Dashboard/Calendar.js
import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // para interactuar con eventos

const Calendar = () => {
  // Estado para los eventos
  const [events, setEvents] = useState([
    {
      id: 1,
      title: 'Reunión de Equipo',
      start: '2024-10-10T10:00:00',
      end: '2024-10-10T11:00:00',
    },
    {
      id: 2,
      title: 'Consulta con Cliente',
      start: '2024-10-12T14:00:00',
      end: '2024-10-12T15:30:00',
    },
  ]);

  // Manejar la adición de nuevos eventos
  const handleDateClick = (arg) => {
    const title = prompt('Ingrese el título del evento:');
    const calendarApi = arg.view.calendar;

    calendarApi.unselect(); // Desseleccionar el rango seleccionado

    if (title) {
      const newEvent = {
        id: events.length + 1, // En un caso real, usa IDs únicos como UUID
        title,
        start: arg.start,
        end: arg.end,
      };
      setEvents([...events, newEvent]);
    }
  };

  // Manejar el clic en un evento para eliminarlo
  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `¿Está seguro de que desea eliminar el evento "${clickInfo.event.title}"?`
      )
    ) {
      clickInfo.event.remove();
      setEvents(events.filter((event) => event.id !== parseInt(clickInfo.event.id)));
    }
  };

  return (
    <div className="max-w-7xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-8 text-blue-600">
        Calendario de Citas
      </h2>
      <div className="border border-gray-200 rounded-lg">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          events={events}
          selectable={true}
          selectMirror={true}
          select={handleDateClick}
          eventClick={handleEventClick}
          height="auto"
          themeSystem="standard"
        />
      </div>
    </div>
  );
};

export default Calendar;
