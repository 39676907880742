// src/pages/LandingPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import Timeline from "../components/landingPage/Timeline";
import PublicNavbar from "../components/layout/PublicNavbar";
import Button from '../components/ui/button'; // Importar botón personalizado
import Card from '../components/ui/card'; // Importar card personalizado

const LandingPage = () => {
    return (
        <div className="landing-page min-h-screen bg-gray-100 flex flex-col">
            {/* PublicNavbar */}
            <PublicNavbar/>

            {/* Spacer */}
            <div className="mt-20"></div>

            {/* Hero Section */}
            <header className="hero-section bg-primary text-white text-center p-16 mb-12 w-full">
                <div className="hero-content max-w-5xl mx-auto">
                    <h1 className="text-5xl font-extrabold mb-6 animate-fade-in">
                        ¡Bienvenido a <span className="text-secondary">Cotizame</span>!
                    </h1>
                    <p className="text-xl mb-8">
                        Automatiza y optimiza el flujo de ventas de tu negocio de manera eficiente y sin complicaciones.
                    </p>
                </div>
            </header>

            {/* Features Section */}
            <section className="features-section w-11/12 max-w-6xl mb-16 text-center mx-auto">
                <h2 className="text-4xl font-semibold mb-10 text-primary">Nuestros Servicios</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
                    <Feature
                        title="Cotizaciones Automatizadas"
                        description="Responde rápidamente a tus clientes con cotizaciones precisas generadas en segundos."
                        icon="💻"
                    />
                    <Feature
                        title="Generación de Citas Inteligentes"
                        description="Optimiza tu agenda y mejora la satisfacción del cliente con nuestro sistema de calendario inteligente."
                        icon="📅"
                    />
                    <Feature
                        title="Informes Detallados"
                        description="Toma decisiones informadas con insights valiosos proporcionados por nuestros informes automáticos."
                        icon="📊"
                    />
                    <Feature
                        title="Búsqueda de FindCustomers Potenciales"
                        description="Amplía tu alcance y aumenta tus oportunidades de negocio encontrando clientes potenciales en tu área."
                        icon="🔍"
                    />
                </div>
            </section>

            {/* Timeline Section */}
            <Timeline />

            {/* CTA Section */}
            <section className="cta-section w-full bg-gray-200 py-16 text-center">
                <h2 className="text-4xl font-semibold mb-6 text-primary">
                    Transforma tu negocio hoy. ¡Regístrate ahora!
                </h2>
                <Link to="/register">
                    <Button className="mt-4" variant="primary" size="lg">
                        Registrarme
                    </Button>
                </Link>
            </section>

            {/* Footer */}
            <footer className="w-full bg-primary text-white py-4 text-center">
                <p>© 2024 Cotizame. Todos los derechos reservados.</p>
            </footer>
        </div>
    );
};

// Feature component para reutilización
const Feature = ({ title, description, icon }) => (
    <Card className="feature flex flex-col items-center">
        <div className="feature-icon text-5xl mb-4">{icon}</div>
        <h3 className="text-2xl font-semibold mb-4 text-primary">{title}</h3>
        <p className="text-base text-gray-700">{description}</p>
    </Card>
);

export default LandingPage;
