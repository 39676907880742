// src/pages/LoginPage.js
import axios from "axios";
import React, { useState, useContext } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import PublicNavbar from "../components/layout/PublicNavbar";

const LoginPage = () => {
    const { user, login } = useContext(AuthContext);
    const history = useHistory();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Si el usuario ya está autenticado, redirígelo al Dashboard
    if (user) {
        return <Redirect to="/dashboard" />;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
        const credentials = { username, password };

        try {

            console.log("iniciando sesion....")
            // const response = await axios.post('http://localhost:8000/api/accounts/login/', credentials);
            const response = await axios.post('https://cotizame.lat/api/accounts/login/', credentials);
            console.log(response)
            // La respuesta ahora incluye 'user'
            const { access, refresh, user: userData } = response.data;

            if (!userData) {
                throw new Error('No se proporcionaron datos del usuario en la respuesta.');
            }

            // Almacena los tokens en localStorage
            localStorage.setItem('access_token', access);
            localStorage.setItem('refresh_token', refresh);

            // Actualiza el contexto de autenticación con los datos del usuario
            login(userData);

            setMessage('Inicio de sesión exitoso');
            console.log('Inicio de sesión exitoso:', userData);

            // Redirige al Dashboard
            history.push('/dashboard');
        } catch (error) {
            if (error.response) {
                console.log(error)
                // Manejo de errores de respuesta del servidor
                setMessage('Error de inicio de sesión: ' + (error.response.data.error || 'Credenciales inválidas'));
            } else {
                // Manejo de errores de conexión u otros errores
                setMessage('Error de conexión. Por favor, intenta de nuevo.');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            {/* Navbar Público */}
            <PublicNavbar />

            {/* Contenido de la Página de Login */}
            <div className="flex items-center justify-center flex-grow bg-gradient-to-r from-indigo-500 to-purple-600 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full bg-white p-8 rounded-xl shadow-xl">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                            Inicia sesión en tu cuenta
                        </h2>
                        <p className="mt-2 text-center text-sm text-gray-600">
                            O{' '}
                            <Link to="/register" className="font-medium text-indigo-600 hover:text-indigo-500">
                                regístrate aquí
                            </Link>
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-6 mt-6">
                        <div>
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                Nombre de usuario
                            </label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                placeholder="Ingresa tu nombre de usuario"
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-150 ease-in-out"
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                Contraseña
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                placeholder="Ingresa tu contraseña"
                                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm transition duration-150 ease-in-out"
                            />
                        </div>

                        {message && (
                            <div className={`text-sm ${message.startsWith('Error') ? 'text-red-600' : 'text-green-600'}`} role="alert">
                                {message}
                            </div>
                        )}

                        <div>
                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out ${
                                    loading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            >
                                {loading ? 'Iniciando sesión...' : 'Iniciar sesión'}
                            </button>
                        </div>
                    </form>

                    {/* Separador Opcional */}
                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default LoginPage;
