// src/components/ui/button.jsx
import React from 'react';
import { cn } from '../../utils/cn'; // Utilidad para combinar clases, puedes crearla o usar una librería como classnames

const Button = React.forwardRef(({ className, variant = 'default', size = 'md', ...props }, ref) => {
  const baseStyles = 'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2';

  const variantStyles = {
    default: 'bg-primary text-white hover:bg-primaryDark focus:ring-primaryDark',
    secondary: 'bg-secondary text-white hover:bg-yellow-500 focus:ring-secondary',
    accent: 'bg-accent text-white hover:bg-green-500 focus:ring-accent',
  };

  const sizeStyles = {
    sm: 'px-3 py-1.5',
    md: 'px-4 py-2',
    lg: 'px-5 py-3',
  };

  return (
    <button
      ref={ref}
      className={cn(baseStyles, variantStyles[variant], sizeStyles[size], className)}
      {...props}
    />
  );
});

Button.displayName = 'Button';

export default Button;
