// src/components/ui/dialog.jsx
import React from 'react';

export const Dialog = ({ onClose, children }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div
      className="fixed inset-0 bg-black opacity-50"
      onClick={onClose}
    ></div>
    <div className="bg-white rounded-lg shadow-lg z-10 max-w-lg w-full">
      {children}
    </div>
  </div>
);

export const DialogHeader = ({ children }) => (
  <div className="px-6 py-4 border-b border-gray-200">
    <h2 className="text-xl font-semibold">{children}</h2>
  </div>
);

export const DialogBody = ({ children }) => (
  <div className="px-6 py-4">{children}</div>
);

export const DialogFooter = ({ children }) => (
  <div className="px-6 py-4 border-t border-gray-200 flex justify-end space-x-2">
    {children}
  </div>
);
