// src/components/Layout/PublicNavbar.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const PublicNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="w-full bg-white shadow-md fixed top-0 left-0 z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    {/* Logo / Brand */}
                    <div className="flex-shrink-0 flex items-center">
                        <Link to="/home" className="text-2xl font-bold text-blue-600">
                            Cotizame
                        </Link>
                    </div>
                    {/* Botón de menú hamburguesa para móviles */}
                    <div className="flex items-center md:hidden">
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-blue-600 hover:text-blue-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            aria-controls="mobile-menu"
                            aria-expanded={isOpen}
                        >
                            <span className="sr-only">Abrir menú principal</span>
                            {/* Icono de menú abierto/cerrado */}
                            {isOpen ? (
                                // Icono de cerrar (X)
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            ) : (
                                // Icono de menú (hamburguesa)
                                <svg
                                    className="block h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                    {/* Navigation Links para escritorio */}
                    <div className="hidden md:flex items-center space-x-6">
                        {/* Enlace "Inicio" */}
                        <Link to="/home">
                            <button className="text-blue-600 hover:text-blue-800 font-medium">
                                Inicio
                            </button>
                        </Link>
                        {/* Enlace "Iniciar Sesión" */}
                        <Link to="/login">
                            <button className="text-blue-600 hover:text-blue-800 font-medium">
                                Iniciar Sesión
                            </button>
                        </Link>
                        {/* Enlace "Registrarme" */}
                        <Link to="/register">
                            <button className="bg-blue-600 text-white px-5 py-2 rounded-full hover:bg-blue-700 transition duration-300">
                                Registrarme
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            {/* Menú móvil desplegable */}
            {isOpen && (
                <div className="md:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        {/* Enlace "Inicio" */}
                        <Link to="/home">
                            <button className="block text-left w-full text-blue-600 hover:text-blue-800 hover:bg-gray-100 px-3 py-2 rounded-md text-base font-medium">
                                Inicio
                            </button>
                        </Link>
                        {/* Enlace "Iniciar Sesión" */}
                        <Link to="/login">
                            <button className="block text-left w-full text-blue-600 hover:text-blue-800 hover:bg-gray-100 px-3 py-2 rounded-md text-base font-medium">
                                Iniciar Sesión
                            </button>
                        </Link>
                        {/* Enlace "Registrarme" */}
                        <Link to="/register">
                            <button className="block text-left w-full bg-blue-600 text-white px-3 py-2 rounded-md text-base font-medium hover:bg-blue-700">
                                Registrarme
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default PublicNavbar;
