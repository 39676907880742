// src/components/Dashboard/Cotizaciones.js
import React from 'react';

const Cotizaciones = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Cotizaciones</h2>
      {/* Aquí puedes agregar la lógica para manejar cotizaciones */}
      <p>Listado de cotizaciones...</p>
    </div>
  );
};

export default Cotizaciones;
