// src/components/Dashboard/Business.js
import React, { useState, useRef } from 'react';

// Importar los componentes de la tabla
import {
  Table,
  TableHeader,
  TableRow,
  TableHeadCell,
  TableCell,
  TableBody,
} from '../ui/table';

// Importar los componentes del diálogo
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '../ui/dialog';

// Importar los íconos de Heroicons v2
import {
  PlusCircleIcon,
  TrashIcon,
  PencilIcon,
  ArrowUpOnSquareIcon,
} from '@heroicons/react/24/outline';

const Business = () => {
  // Estado para la información del negocio
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    description: '',
    address: '',
    // Añade más campos según sea necesario
  });

  // Estados para las listas
  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);

  // Estados para controlar los diálogos
  const [isBranchDialogOpen, setIsBranchDialogOpen] = useState(false);
  const [isProductDialogOpen, setIsProductDialogOpen] = useState(false);
  const [isServiceDialogOpen, setIsServiceDialogOpen] = useState(false);
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);

  // Estados para los formularios de los diálogos
  const [branchForm, setBranchForm] = useState({ name: '', address: '', phone: '' });
  const [productForm, setProductForm] = useState({ name: '', price: '', description: '' });
  const [serviceForm, setServiceForm] = useState({ name: '', price: '', description: '' });

  // Estados para el elemento que se está editando
  const [editingBranchId, setEditingBranchId] = useState(null);
  const [editingProductId, setEditingProductId] = useState(null);
  const [editingServiceId, setEditingServiceId] = useState(null);

  // Referencia para el input de archivo
  const fileInputRef = useRef(null);

  // Manejar cambios en la información del negocio
  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  // Manejar el envío del formulario del negocio
  const handleSubmitBusiness = (e) => {
    e.preventDefault();
    // Aquí puedes procesar los datos o enviarlos a una API
    console.log('Información del Negocio:', businessInfo);
  };

  // Manejar el envío del formulario de sucursales
  const handleAddOrEditBranch = (e) => {
    e.preventDefault();
    if (editingBranchId) {
      // Editar sucursal existente
      setBranches(
        branches.map((branch) =>
          branch.id === editingBranchId ? { ...branch, ...branchForm } : branch
        )
      );
    } else {
      // Agregar nueva sucursal
      setBranches([...branches, { id: Date.now(), ...branchForm }]);
    }
    setBranchForm({ name: '', address: '', phone: '' });
    setEditingBranchId(null);
    setIsBranchDialogOpen(false);
  };

  // Manejar el envío del formulario de productos
  const handleAddOrEditProduct = (e) => {
    e.preventDefault();
    if (editingProductId) {
      // Editar producto existente
      setProducts(
        products.map((product) =>
          product.id === editingProductId ? { ...product, ...productForm } : product
        )
      );
    } else {
      // Agregar nuevo producto
      setProducts([...products, { id: Date.now(), ...productForm }]);
    }
    setProductForm({ name: '', price: '', description: '' });
    setEditingProductId(null);
    setIsProductDialogOpen(false);
  };

  // Manejar el envío del formulario de servicios
  const handleAddOrEditService = (e) => {
    e.preventDefault();
    if (editingServiceId) {
      // Editar servicio existente
      setServices(
        services.map((service) =>
          service.id === editingServiceId ? { ...service, ...serviceForm } : service
        )
      );
    } else {
      // Agregar nuevo servicio
      setServices([...services, { id: Date.now(), ...serviceForm }]);
    }
    setServiceForm({ name: '', price: '', description: '' });
    setEditingServiceId(null);
    setIsServiceDialogOpen(false);
  };

  // Funciones para eliminar elementos
  const removeBranch = (id) => {
    setBranches(branches.filter((branch) => branch.id !== id));
  };

  const removeProduct = (id) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const removeService = (id) => {
    setServices(services.filter((service) => service.id !== id));
  };

  // Funciones para abrir el diálogo en modo edición
  const editBranch = (branch) => {
    setBranchForm(branch);
    setEditingBranchId(branch.id);
    setIsBranchDialogOpen(true);
  };

  const editProduct = (product) => {
    setProductForm(product);
    setEditingProductId(product.id);
    setIsProductDialogOpen(true);
  };

  const editService = (service) => {
    setServiceForm(service);
    setEditingServiceId(service.id);
    setIsServiceDialogOpen(true);
  };

  // Función para manejar la importación de productos
  const handleImportProducts = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Aquí puedes implementar la lógica para procesar el archivo Excel
      console.log('Archivo seleccionado:', file);
      // Por ejemplo, podrías usar una librería como SheetJS para leer el archivo
    }
  };

  // Definir una constante para las clases comunes de los botones
  const buttonClasses = "flex items-center px-4 py-2 text-white rounded-lg hover:bg-opacity-80 transition-colors whitespace-nowrap text-base h-10";

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      {/* Información del Negocio */}
      <h2 className="text-3xl font-bold text-center mb-8 text-blue-600">
        Información de Mi Negocio
      </h2>
      <form onSubmit={handleSubmitBusiness} className="space-y-8">
        <div className="bg-gray-50 p-6 rounded-lg shadow-sm">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">
            Detalles del Negocio
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="name"
              >
                Nombre del Negocio
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={businessInfo.name}
                onChange={handleBusinessChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ingrese el nombre de su negocio"
                required
              />
            </div>
            <div>
              <label
                className="block text-gray-700 font-medium mb-2"
                htmlFor="address"
              >
                Dirección
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={businessInfo.address}
                onChange={handleBusinessChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Ingrese la dirección de su negocio"
                required
              />
            </div>
          </div>
          <div className="mt-6">
            <label
              className="block text-gray-700 font-medium mb-2"
              htmlFor="description"
            >
              Descripción
            </label>
            <textarea
              id="description"
              name="description"
              value={businessInfo.description}
              onChange={handleBusinessChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="4"
              placeholder="Ingrese una descripción de su negocio"
              required
            ></textarea>
          </div>
          <div className="text-center mt-6">
            <button
              type="submit"
              className="inline-flex items-center px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition-colors"
            >
              Guardar Información del Negocio
            </button>
          </div>
        </div>
      </form>

      {/* Botones de Acción */}
      <div className="mt-12 flex justify-start items-center space-x-4">
        <button
          type="button"
          onClick={() => {
            setBranchForm({ name: '', address: '', phone: '' });
            setEditingBranchId(null);
            setIsBranchDialogOpen(true);
          }}
          className={`${buttonClasses} bg-blue-500 hover:bg-blue-600`}
        >
          <PlusCircleIcon className="h-5 w-5 mr-2" />
          Agregar Sucursal
        </button>

        <button
          type="button"
          onClick={() => {
            setProductForm({ name: '', price: '', description: '' });
            setEditingProductId(null);
            setIsProductDialogOpen(true);
          }}
          className={`${buttonClasses} bg-blue-500 hover:bg-blue-600`}
        >
          <PlusCircleIcon className="h-5 w-5 mr-2" />
          Agregar Producto
        </button>

        <button
          type="button"
          onClick={() => {
            setServiceForm({ name: '', price: '', description: '' });
            setEditingServiceId(null);
            setIsServiceDialogOpen(true);
          }}
          className={`${buttonClasses} bg-blue-500 hover:bg-blue-600`}
        >
          <PlusCircleIcon className="h-5 w-5 mr-2" />
          Agregar Servicio
        </button>

        <button
          type="button"
          onClick={() => fileInputRef.current.click()}
          className={`${buttonClasses} bg-purple-500 hover:bg-purple-600`}
        >
          <ArrowUpOnSquareIcon className="h-5 w-5 mr-2" />
          Importar Productos desde Excel
        </button>

        {/* Input de archivo oculto */}
        <input
          type="file"
          accept=".xlsx, .xls"
          ref={fileInputRef}
          onChange={handleImportProducts}
          className="hidden"
        />
      </div>

      {/* Sección de Sucursales */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Sucursales</h3>
        {branches.length === 0 ? (
          <p className="text-gray-600">No hay sucursales agregadas.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeadCell>Nombre</TableHeadCell>
                <TableHeadCell>Dirección</TableHeadCell>
                <TableHeadCell>Teléfono</TableHeadCell>
                <TableHeadCell>Acciones</TableHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {branches.map((branch) => (
                <TableRow key={branch.id}>
                  <TableCell>{branch.name}</TableCell>
                  <TableCell>{branch.address}</TableCell>
                  <TableCell>{branch.phone}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editBranch(branch)}
                        className="text-blue-500 hover:text-blue-600"
                        title="Editar Sucursal"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => removeBranch(branch.id)}
                        className="text-red-500 hover:text-red-600"
                        title="Eliminar Sucursal"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {/* Sección de Productos */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Productos</h3>
        {products.length === 0 ? (
          <p className="text-gray-600">No hay productos agregados.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeadCell>Nombre</TableHeadCell>
                <TableHeadCell>Precio</TableHeadCell>
                <TableHeadCell>Descripción</TableHeadCell>
                <TableHeadCell>Acciones</TableHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>${parseFloat(product.price).toFixed(2)}</TableCell>
                  <TableCell>{product.description}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editProduct(product)}
                        className="text-blue-500 hover:text-blue-600"
                        title="Editar Producto"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => removeProduct(product.id)}
                        className="text-red-500 hover:text-red-600"
                        title="Eliminar Producto"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {/* Sección de Servicios */}
      <div className="mt-12">
        <h3 className="text-2xl font-semibold text-gray-800 mb-4">Servicios</h3>
        {services.length === 0 ? (
          <p className="text-gray-600">No hay servicios agregados.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHeadCell>Nombre</TableHeadCell>
                <TableHeadCell>Precio</TableHeadCell>
                <TableHeadCell>Descripción</TableHeadCell>
                <TableHeadCell>Acciones</TableHeadCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {services.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.name}</TableCell>
                  <TableCell>${parseFloat(service.price).toFixed(2)}</TableCell>
                  <TableCell>{service.description}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => editService(service)}
                        className="text-blue-500 hover:text-blue-600"
                        title="Editar Servicio"
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        onClick={() => removeService(service.id)}
                        className="text-red-500 hover:text-red-600"
                        title="Eliminar Servicio"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>

      {/* Diálogo para Agregar/Editar Sucursal */}
      {isBranchDialogOpen && (
        <Dialog onClose={() => setIsBranchDialogOpen(false)}>
          <DialogHeader>
            {editingBranchId ? 'Editar Sucursal' : 'Agregar Sucursal'}
          </DialogHeader>
          <form onSubmit={handleAddOrEditBranch}>
            <DialogBody>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-gray-700" htmlFor="branch-name">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="branch-name"
                    name="name"
                    value={branchForm.name}
                    onChange={(e) =>
                      setBranchForm({ ...branchForm, name: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700" htmlFor="branch-address">
                    Dirección
                  </label>
                  <input
                    type="text"
                    id="branch-address"
                    name="address"
                    value={branchForm.address}
                    onChange={(e) =>
                      setBranchForm({ ...branchForm, address: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700" htmlFor="branch-phone">
                    Teléfono
                  </label>
                  <input
                    type="text"
                    id="branch-phone"
                    name="phone"
                    value={branchForm.phone}
                    onChange={(e) =>
                      setBranchForm({ ...branchForm, phone: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                  />
                </div>
              </div>
            </DialogBody>
            <DialogFooter>
              <button
                type="button"
                onClick={() => {
                  setIsBranchDialogOpen(false);
                  setEditingBranchId(null);
                }}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                {editingBranchId ? 'Guardar Cambios' : 'Agregar'}
              </button>
            </DialogFooter>
          </form>
        </Dialog>
      )}

      {/* Diálogo para Agregar/Editar Producto */}
      {isProductDialogOpen && (
        <Dialog onClose={() => setIsProductDialogOpen(false)}>
          <DialogHeader>
            {editingProductId ? 'Editar Producto' : 'Agregar Producto'}
          </DialogHeader>
          <form onSubmit={handleAddOrEditProduct}>
            <DialogBody>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-gray-700" htmlFor="product-name">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="product-name"
                    name="name"
                    value={productForm.name}
                    onChange={(e) =>
                      setProductForm({ ...productForm, name: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700" htmlFor="product-price">
                    Precio
                  </label>
                  <input
                    type="number"
                    id="product-price"
                    name="price"
                    value={productForm.price}
                    onChange={(e) =>
                      setProductForm({ ...productForm, price: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700"
                    htmlFor="product-description"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="product-description"
                    name="description"
                    value={productForm.description}
                    onChange={(e) =>
                      setProductForm({ ...productForm, description: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>
            </DialogBody>
            <DialogFooter>
              <button
                type="button"
                onClick={() => {
                  setIsProductDialogOpen(false);
                  setEditingProductId(null);
                }}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                {editingProductId ? 'Guardar Cambios' : 'Agregar'}
              </button>
            </DialogFooter>
          </form>
        </Dialog>
      )}

      {/* Diálogo para Agregar/Editar Servicio */}
      {isServiceDialogOpen && (
        <Dialog onClose={() => setIsServiceDialogOpen(false)}>
          <DialogHeader>
            {editingServiceId ? 'Editar Servicio' : 'Agregar Servicio'}
          </DialogHeader>
          <form onSubmit={handleAddOrEditService}>
            <DialogBody>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="block text-gray-700" htmlFor="service-name">
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="service-name"
                    name="name"
                    value={serviceForm.name}
                    onChange={(e) =>
                      setServiceForm({ ...serviceForm, name: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700" htmlFor="service-price">
                    Precio
                  </label>
                  <input
                    type="number"
                    id="service-price"
                    name="price"
                    value={serviceForm.price}
                    onChange={(e) =>
                      setServiceForm({ ...serviceForm, price: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    required
                    min="0"
                    step="0.01"
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700"
                    htmlFor="service-description"
                  >
                    Descripción
                  </label>
                  <textarea
                    id="service-description"
                    name="description"
                    value={serviceForm.description}
                    onChange={(e) =>
                      setServiceForm({ ...serviceForm, description: e.target.value })
                    }
                    className="w-full px-3 py-2 border rounded-lg"
                    rows="3"
                    required
                  ></textarea>
                </div>
              </div>
            </DialogBody>
            <DialogFooter>
              <button
                type="button"
                onClick={() => {
                  setIsServiceDialogOpen(false);
                  setEditingServiceId(null);
                }}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg"
              >
                {editingServiceId ? 'Guardar Cambios' : 'Agregar'}
              </button>
            </DialogFooter>
          </form>
        </Dialog>
      )}
    </div>
  );
};

export default Business;
