import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { cn } from '../../utils/cn';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Button from '../ui/button';

const DashboardNavbar = () => {
    const { logout, user } = useContext(AuthContext);
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        logout();
        history.push('/login');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="w-full bg-white shadow-md fixed top-0 left-0 z-50">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    {/* Logo / Brand */}
                    <div className="flex-shrink-0 flex items-center">
                        <Link to="/dashboard" className="text-2xl font-bold text-blue-600">
                            Cotizame
                        </Link>
                    </div>
                    {/* Botón de menú hamburguesa para móviles */}
                    <div className="flex items-center md:hidden">
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="inline-flex items-center justify-center p-2 rounded-md text-blue-600 hover:text-blue-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            aria-controls="mobile-menu"
                            aria-expanded={isOpen}
                        >
                            <span className="sr-only">Abrir menú principal</span>
                            {isOpen ? (
                                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                            ) : (
                                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                            )}
                        </button>
                    </div>
                    {/* Navigation Links para escritorio */}
                    <div className="hidden md:flex items-center space-x-4">
                        <Link to="/dashboard">
                            <Button variant="default" size="sm">
                                Inicio
                            </Button>
                        </Link>
                        <Link to="/dashboard/cotizaciones">
                            <Button variant="default" size="sm">
                                Cotizaciones
                            </Button>
                        </Link>
                        <Link to="/dashboard/calendario">
                            <Button variant="default" size="sm">
                                Calendario
                            </Button>
                        </Link>
                        <Link to="/dashboard/negocio">
                            <Button variant="default" size="sm">
                                Mi Negocio
                            </Button>
                        </Link>
                        <Link to="/dashboard/clientes">
                            <Button variant="default" size="sm">
                                Encontrar Clientes
                            </Button>
                        </Link>
                        <Link to="/dashboard/ajustes">
                            <Button variant="default" size="sm">
                                Ajustes
                            </Button>
                        </Link>
                        <Button variant="logout" size="sm" onClick={handleLogout}>
                            Cerrar Sesión
                        </Button>
                    </div>
                </div>
            </div>

            {/* Menú móvil desplegable */}
            <div
                className={cn(
                    'md:hidden transition-all duration-300 ease-out',
                    isOpen ? 'max-h-screen opacity-100 translate-y-0' : 'max-h-0 opacity-0 -translate-y-2 overflow-hidden'
                )}
                id="mobile-menu"
            >
                <div className="px-2 pt-2 pb-3 sm:px-3">
                    <div className="mb-4">
                        <Link to="/dashboard" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Inicio
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/dashboard/cotizaciones" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Cotizaciones
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/dashboard/calendario" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Calendario
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/dashboard/negocio" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Mi Negocio
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/dashboard/clientes" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Encontrar Clientes
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Link to="/dashboard/ajustes" onClick={() => setIsOpen(false)}>
                            <Button variant="default" size="sm" className="w-full text-left">
                                Ajustes
                            </Button>
                        </Link>
                    </div>
                    <div className="mb-4">
                        <Button
                            variant="logout"
                            size="sm"
                            onClick={() => {
                                handleLogout();
                                setIsOpen(false);
                            }}
                            className="w-full text-left"
                        >
                            Cerrar Sesión
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default DashboardNavbar;