// src/components/Dashboard/Ajustes.js
import React from 'react';

const Ajustes = () => {
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Ajustes</h2>
      {/* Aquí puedes agregar opciones de configuración */}
      <p>Opciones de configuración...</p>
    </div>
  );
};

export default Ajustes;
