// src/components/ui/table.js
import React from 'react';
import PropTypes from 'prop-types';

export const Table = ({ children }) => (
  <div className="overflow-x-auto">
    <table className="min-w-full divide-y divide-gray-200">
      {children}
    </table>
  </div>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableHeader = ({ children }) => (
  <thead className="bg-gray-50">
    {children}
  </thead>
);

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableBody = ({ children }) => (
  <tbody className="bg-white divide-y divide-gray-200">
    {children}
  </tbody>
);

TableBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableRow = ({ children, className = '', ...props }) => (
  <tr className={className} {...props}>
    {children}
  </tr>
);

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const TableHeadCell = ({ children }) => (
  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
    {children}
  </th>
);

TableHeadCell.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TableCell = ({ children }) => (
  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
    {children}
  </td>
);

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
};
