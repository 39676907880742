// src/pages/Dashboard.js
import React from 'react';
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';
import DashboardNavbar from "../components/layout/DashboardNavbar";
import DashboardContent from '../components/Dashboard/DashboardContent';
import Cotizaciones from '../components/Dashboard/Cotizaciones';
import Calendar from '../components/Dashboard/Calendar';
import Bussiness from '../components/Dashboard/Bussiness';
import FindCustomers from '../components/Dashboard/FindCustomers';
import Ajustes from '../components/Dashboard/Ajustes';

const Dashboard = () => {
    let { path } = useRouteMatch();

    return (
        <div>
            <DashboardNavbar /> {/* Navbar condicional según AuthContext */}
            <div className="container mx-auto px-4 py-6 mt-16"> {/* mt-16 para compensar el Navbar fijo */}
                <Switch>
                    <Route exact path={path} component={DashboardContent} />
                    <Route path={`${path}/cotizaciones`} component={Cotizaciones} />
                    <Route path={`${path}/calendario`} component={Calendar} />
                    <Route path={`${path}/negocio`} component={Bussiness} />
                    <Route path={`${path}/clientes`} component={FindCustomers} />
                    <Route path={`${path}/ajustes`} component={Ajustes} />
                    {/* Redireccionar a Dashboard principal si la ruta no coincide */}
                    <Route render={() => <Redirect to={path} />} />
                </Switch>
            </div>
        </div>
    );
};

export default Dashboard;
