// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import LandingPage from './pages/LandingPage';
import Dashboard from './pages/Dashboard';

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className="min-h-screen bg-gray-100">
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/home" />} />
                        <Route path="/home" component={LandingPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/register" component={RegisterPage} />
                        <ProtectedRoute path="/dashboard" component={Dashboard} />
                        {/* Ruta por defecto */}
                        <Route render={() => <Redirect to="/home" />} />
                    </Switch>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
