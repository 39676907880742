// src/components/ui/label.js
import React from 'react';
import PropTypes from 'prop-types';

const Label = React.forwardRef(({ className = '', children, ...props }, ref) => {
  return (
    <label
      ref={ref}
      className={`block text-sm font-medium text-gray-700 ${className}`}
      {...props}
    >
      {children}
    </label>
  );
});

Label.displayName = 'Label';

Label.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export { Label };
